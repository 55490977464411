.indicator {
	width: 0.8rem;
	height: 0.8rem;
	position: absolute;
	margin-left: 1.22rem;
	margin-top: 1.22rem;
	border-radius: 50%;
	border: 2px solid black;
}

.network-button:focus-within > .network-dropdown {
	display: block;
}

@media screen and (min-width: 1024px) {
	.nav-menu {
		display: none !important;
	}
}

.more-dropdown {
	display: none;
	position: absolute;
	bottom: 69px;
	right: 0;
	padding-top: 1em;
}

.more-button:focus-within > .more-dropdown {
	display: block;
}

.explore-dropdown {
	display: none;
	position: absolute;
	bottom: 69px;
	left: 0;
	padding-block: 0.5em;
}

.explore-button:focus-within > .explore-dropdown {
	display: block;
}
