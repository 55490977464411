@import "./margins.css";
@import "./padding.css";

html {
  overflow: hidden;
  height: 100vh;
}

body {
  /* font-family: "Poppins", sans-serif; */
  /* font-family: Arial, Helvetica, sans-serif, sans-serif; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  height: 100%;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.section {
  padding-bottom: 8em;
}

/* Rules for sizing the icon. */
.md-12 {
  font-size: 12px;
}

.md-14 {
  font-size: 14px;
}
.md-18 {
  font-size: 18px;
}
.md-20 {
  font-size: 20px;
}
.md-24 {
  font-size: 24px;
}
.md-28 {
  font-size: 28px;
}
.md-36 {
  font-size: 36px;
}
.md-48 {
  font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

.mt-auto {
  margin-top: auto;
}
.mr-auto {
  margin-right: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.m-auto {
  margin: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.h-100 {
  height: 100%;
}

.confirmations-0 {
  background-color: #d84846;
}

.confirmations-1 {
  background-color: #d16b45;
}

.confirmations-2 {
  background-color: #cb8a45;
}

.confirmations-3 {
  background-color: #c4a644;
}

.confirmations-4 {
  background-color: #c1b344;
}

.confirmations-5 {
  background-color: #bbbd43;
}

.confirmations-6 {
  background-color: #97b742;
}

.confirmations-7 {
  background-color: #86b342;
}

.confirmations-8 {
  background-color: #76b041;
}

.spinner {
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  100% {
    transform: rotate(-360deg);
  }
}

.truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.capitalize {
  text-transform: capitalize;
}

.highlight-button {
  color: lightgrey;
  background: none;
  border: none;
  cursor: pointer;
}

.highlight-button:is(:hover, :focus) {
  color: grey;
}

.shadow {
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%)
}

.rounded {
  border-radius: .25em;
}

.info-table {
  border: 1px lightgrey solid;
}